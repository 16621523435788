import Axios from "axios";
import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class CategoryExpensesService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listCategoryExpenses (filters) {
		const response = await this.axios.get("categoryExpenses/listCategoryExpenses", { params: { filters } });
		return response.data;
	}

	async upsertCategoryExpense (payload) {
		const response = await this.axios.post("categoryExpenses/upsertCategoryExpense", payload);
		return response.data;
	}

	async deleteCategoryExpense (id) {
		const response = await this.axios.delete("categoryExpenses/deleteCategoryExpense", { params: id });
		return response.data;
	}
}
