<template>
	<main
		:class="fluid ? 'container-fluid' : 'container'"
		class="card tcs-top py-0"
		:style="`width: calc(${width || '95'}% - ${
			sideBarWidth + +fluid * 16
		}px + 30px) !important; left: ${Math.floor(sideBarWidth / 2)}px;`"
	>
		<slot />
	</main>
</template>

<script>
	import { mapState } from "vuex";

	export default {
		props: {
			width: {
				type: String
			},
			fluid: {
				type: Boolean,
				default: false
			}
		},
		computed: mapState(["sideBarWidth"])
	};
</script>

<style scoped>
	main {
		/* min-height: 75vh; */
		margin-top: 80px;
		background-color: rgba(0, 0, 0, 0);
		border-style: none;
		transition: all ease 0.3s;
	}

	@media (max-width: 790px) {
		.card h1 {
			font-size: 34px;
		}

		.card.tcs-card.tcs-top {
			min-height: calc(100vh - 70px);
			margin-top: 70px;
		}
	}
</style>
