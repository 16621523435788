import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class PaymentScheduleService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listPaymentSchedule (filters) {
		const response = await this.axios.get("paymentSchedule/listPaymentSchedule", { params: { filters } });
		return response.data;
	}

	async createPaymentSchedule (payload) {
		const response = await this.axios.post("paymentSchedule/createPaymentSchedule", payload);
		return response.data;
	}

	async updatePaymentSchedule (payload) {
		const response = await this.axios.patch("paymentSchedule/updatePaymentSchedule", payload);
		return response.data;
	}

	async deletePaymentSchedule (payload) {
		const response = await this.axios.patch("paymentSchedule/deletePaymentSchedule", payload);
		return response.data;
	}

	async getPaymentScheduleByParams (filters) {
		const response = await this.axios.get("paymentSchedule/getPaymentScheduleByParams", { params: { filters } });
		return response.data;
	}

	async executePayments (payload) {
		const response = await this.axios.patch("paymentSchedule/executePayments", payload);
		return response.data;
	}

	async recoverPaymentSchedule (payload) {
		const response = await this.axios.patch("paymentSchedule/recoverPaymentSchedule", payload);
		return response.data;
	}
}
